import React, { useState, useEffect } from "react";
import $ from "jquery";
import AppContainer from "../../Components/Structure/AppContainer";
import PageHeader from "../../Components/Common/PageHeader";
import PopAddMatch from "../../Components/Betting/PopAddMatch";
import MatchesList from "../../Components/Betting/MatchesList";

function Matches() {
  const [addMatch, setAddMatch] = useState(false);

  const Buttons = () => {
    return (
      <div className="btn btn-list">
        <a
          className="btn ripple btn-success"
          href="#"
          onClick={onClickAddMatch}
        >
          <i className="fe fe-plus-square"></i> Add Match
        </a>
      </div>
    );
  };

  const onClickAddMatch = () => {
    setAddMatch(<PopAddMatch />);
  };

  useEffect(() => {
    if (addMatch) {
      $("#modal_add_match").modal("show");
    }
  }, [addMatch]);

  return (
    <AppContainer>
      {addMatch}
      <PageHeader
        title="Matches"
        previousPageTitle="Dashboard"
        buttons={<Buttons />}
      />
      <MatchesList />
    </AppContainer>
  );
}

export default Matches;
