import React, { useEffect, useState } from "react";
import $ from "jquery";
import AppContainer from "../../Components/Structure/AppContainer";
import PageHeader from "../../Components/Common/PageHeader";
import PopAddPrice from "../../Components/Betting/PopAddPrice";
import Statistics from "../../Components/Betting/Statistics";
import PopAddPrediction from "../../Components/Betting/PopAddPrediction";
import TitleValueCard from "../../Components/Common/TitleValueCard";
import ajax from "../../ajax";
import ActivityLoader from "../../Components/Common/ActivityLoader";
import functions from "../../functions";
import PopRequestWithdraw from "../../Components/Betting/PopRequestWithdraw";

function Dashboard() {
  const [addPrice, setAddPrice] = useState(false);
  const [prices, setPrices] = useState(false);
  const [requests, setRequests] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [predictions, setPredictions] = useState(false);
  const [addPrediction, setAddPrediction] = useState(false);
  const [balance, setBlance] = useState("...");
  const [tot, setTot] = useState("...");
  const [totalSuccessMessages, setTotalSuccessMessages] = useState("...");
  const [totalUnsuccessMessages, setTotalUnsuccessMessages] = useState("...");
  const [page, setPage] = useState({ page: 1 });

  const Buttons = () => {
    return (
      <div className="btn btn-list">
        {functions.levelGuard() == "1" && (
          <>
            <a
              className="btn ripple btn-success"
              href="#"
              onClick={onClickAddPrice}
            >
              <i className="fe fe-plus-square"></i> Add Price
            </a>
            <a
              className="btn ripple btn-warning"
              href="#"
              onClick={onClickAddPrediction}
            >
              <i className="fe fe-plus-square"></i> Add Prediction
            </a>
          </>
        )}
        {functions.levelGuard() != "1" && (
          <>
            <a
              className="btn ripple btn-success"
              href="#"
              onClick={onClickWithdraw}
            >
              <i className="fe fe-plus-square"></i> Withdraw Request
            </a>
          </>
        )}
      </div>
    );
  };

  const onClickAddPrice = () => {
    setAddPrice(<PopAddPrice getList={BettingPricesList} />);
  };

  const onClickWithdraw = () => {
    setWithdraw(<PopRequestWithdraw getList={BettingWithdrawRequests} />);
  };

  useEffect(() => {
    if (addPrice) {
      $("#modal_add_price").modal("show");
    }
  }, [addPrice]);

  useEffect(() => {
    if (withdraw) {
      $("#modal_request_withdraw").modal("show");
    }
  }, [withdraw]);

  const onClickAddPrediction = () => {
    setAddPrediction(<PopAddPrediction getList={BettingPredictionsList} />);
  };

  useEffect(() => {
    if (addPrediction) {
      $("#modal_add_prediction").modal("show");
    }
  }, [addPrediction]);

  useEffect(() => {
    // getTotalMatches();
    // getTotalMatchWins();
    getTotalSuccessMessages();
    getTotalUnSuccessMessages();
    getBettingStats();
    BettingPricesList();
    BettingPredictionsList();
    BettingWithdrawRequests();
  }, [page]);

  const BettingPricesList = async () => {
    const server_response = await ajax.BettingPricesList();
    if (server_response.status === "OK") {
      setPrices(server_response.details);
    } else {
      setPrices("404");
    }
  };

  const BettingWithdrawRequests = async () => {
    const server_response = await ajax.BettingRequestsList();
    console.log(server_response);
    if (server_response.status === "OK") {
      setRequests(server_response.details);
    } else {
      setRequests("404");
    }
  };

  const BettingPredictionsList = async () => {
    const server_response = await ajax.BettingPredictionsList();
    if (server_response.status === "OK") {
      setPredictions(server_response.details);
    } else {
      setPredictions("404");
    }
  };

  // const getTotalMatches = async () => {
  //   const server_response = await ajax.countMatches();
  //   if (server_response.status === "OK") {
  //     setTotalMatches(server_response.details.total_p);
  //   }
  // };

  const getBettingStats = async () => {
    const server_response = await ajax.getBettingStatistics();
    // console.log(server_response.details);
    if (server_response.status === "OK") {
      // setBettingStata(server_response.details);
      setBlance(server_response.details.balance.total_p);
      setTot(server_response.details.total_cut.total_p);
    }
  };

  // const getTotalMatchWins = async () => {
  //   const server_response = await ajax.countMatchWins();
  //   if (server_response.status === "OK") {
  //     setTotalMatchWins(server_response.details.total_p);
  //   }
  // };

  const getTotalSuccessMessages = async () => {
    const server_response = await ajax.countPaidTips();
    if (server_response.status === "OK") {
      setTotalSuccessMessages(server_response.details.total_p);
    }
  };

  const getTotalUnSuccessMessages = async () => {
    const server_response = await ajax.countUnPaidTips();
    if (server_response.status === "OK") {
      setTotalUnsuccessMessages(server_response.details.total_p);
    }
  };

  return (
    <AppContainer title="Dashboard">
      <PageHeader
        title="Dashboard"
        previousPageTitle="Dashboard"
        buttons={<Buttons />}
      />
      {addPrice}
      {addPrediction}
      {withdraw}

      <div className="row">
        <TitleValueCard
          colSize={3}
          href="#"
          title="Successful Messages"
          value={totalSuccessMessages}
          icon="fas fa-envelope-open"
          color="icon-service rounded-circle bg-success-transparent text-success"
        />
        <TitleValueCard
          colSize={3}
          href="#"
          title="Unsuccessful Messages"
          value={totalUnsuccessMessages}
          icon="fas fa-envelope"
          color="icon-service rounded-circle bg-danger-transparent text-danger"
        />
        <TitleValueCard
          colSize={3}
          href="#"
          title="Cumulative Account Balance"
          value={tot}
          icon="fas fa-baseball-ball"
          color="icon-service rounded-circle bg-primary-transparent text-primary"
        />
        <TitleValueCard
          colSize={3}
          href="#"
          title="Current Account Balance"
          value={balance}
          icon="fas fa-baseball-ball"
          color="icon-service rounded-circle bg-success-transparent text-success"
        />

        <Statistics />

        <div className="col-lg-12 col-sm-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Withdraw Requests</h6>
                <p className="text-muted card-sub-title">
                  Your withdraw requests
                </p>
              </div>
              {!requests && <ActivityLoader />}
              <div className="user-manager scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                  <table className="table table-bordered  text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>
                        <th>Phone Number</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Request Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requests &&
                        Array.isArray(requests) &&
                        requests.map((request, key) => (
                          <tr key={key} className={`hot-tr`}>
                            <td>{key + 1}</td>
                            <td>{request.phone}</td>
                            <td>{request.amount.amount_p}</td>
                            <td>
                              {request.status == 1 ? (
                                <button className="btn btn-primary btn-sm">
                                  Pending
                                </button>
                              ) : request.status == 2 ? (
                                <button className="btn btn-danger btn-sm">
                                  Failed
                                </button>
                              ) : request.status == 3 ? (
                                <button className="btn btn-success btn-sm">
                                  Approved
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {request.date.long_date} {request.date.time}
                            </td>
                          </tr>
                        ))}
                      {requests && !Array.isArray(requests) && (
                        <tr>
                          <td colSpan={5} className="bg-danger bg-light">
                            No withdraw requests found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Tip Prices</h6>
                <p className="text-muted mb-1 card-sub-title">
                  Prices for all betting tips
                </p>
              </div>
              <div className="user-manager scroll-widget border-top overflow-auto">
                {!prices && <ActivityLoader />}
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th style={{ height: "40px" }}>#No</th>
                        <th>Price</th>
                        <th>Matches</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prices &&
                        Array.isArray(prices) &&
                        prices.map((price, key) => (
                          <tr className="border-bottom">
                            <td className="bd-t-0">
                              <div className="main-img-user">{key + 1}</div>
                            </td>
                            <td className="bd-t-0">
                              <h6 className="mg-b-0">
                                {price.amount.amount_c}
                              </h6>
                              <small className="tx-11 tx-gray-500">
                                {price.price_name}
                              </small>
                            </td>
                            <td className="bd-t-0">
                              <h6 className="mg-b-0 font-weight-bold">
                                {price.match_count.total_p}
                              </h6>
                              <small className="tx-11 tx-gray-500">
                                Total Matches
                              </small>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Predictions</h6>
                <p className="text-muted mb-1 card-sub-title">
                  Predictions list to select from
                </p>
              </div>
              <div className="user-manager scroll-widget border-top overflow-auto">
                {!predictions && <ActivityLoader />}
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th style={{ height: "40px" }}>#No</th>
                        <th>Prediction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {predictions &&
                        Array.isArray(predictions) &&
                        predictions.map((prediction, key) => (
                          <tr className="border-bottom">
                            <td className="bd-t-0">
                              <div className="main-img-user">{key + 1}</div>
                            </td>
                            <td className="bd-t-0">
                              <h6 className="mg-b-0">
                                {prediction.prediction}
                              </h6>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Dashboard;
