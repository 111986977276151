import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import TopBar from "../../Components/Common/TopBar";
import PageHeader from "../../Components/Common/PageHeader";
import functions from "../../functions";
import RightSideBar from "../../Components/Common/RightSideBar";
import TotalUsersCard from "../../Components/Cards/Access/TotalUsersCard";
import TotalPermissionsCard from "../../Components/Cards/Access/TotalPermissionsCard";
import ListUsers from "../../Components/Access/ListUsers";
import ListPermissions from "../../Components/Access/ListPermissions";
import PopNewUser from "../../Components/Access/PopNewUser";
import PopNewPermission from "../../Components/Access/PopNewPermission";
import AccessCodes from "../../Components/Access/AccessCodes";
import TitleValueCard from "../../Components/Common/TitleValueCard";
import { Link } from "react-router-dom";
import ajax from "../../ajax";
import EmailValidation from "../../Components/Activity/EmailValidation";
import PhoneValidation from "../../Components/Activity/PhoneValidation";

class Activity extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    newAdmin: false,
    usersList: true,
    permissionList: true,
    newPermission: false,
    totalEmailAuth: "...",
    totalPhoneAuth: "...",
    totalLockedAccount: "...",
  };

  componentDidMount() {
    const accessInfo = functions.readPermissions();
    if (!functions.findInObject(accessInfo, "CONFIG-USERS-0001")) {
      window.location.replace("/dashboard");
    } else {
      this.countEmailAuth();
      this.countPhoneAuth();
    }
  }

  buttons = () => {
    return (
      <>
        <Link
          to="/activity/device_logs"
          title="Which use is logged in with which device"
          className="btn ripple btn-success"
        >
          <i className="fas fa-mobile-alt mr-2"></i>Device Logs
        </Link>
        <button className="btn ripple btn-primary">
          <i className="fas fa-plus-circle mr-2"></i>Locked Accounts
        </button>
        <button className="btn ripple btn-danger">
          <i className="fas fa-plus-circle mr-2"></i>Unsecure Accounts
        </button>
      </>
    );
  };

  countEmailAuth = async () => {
    const server_response = await ajax.countEmailAuth();
    if (server_response.status == "OK") {
      this.setState({
        totalEmailAuth: server_response.details.total_p,
      });
    }
  };

  countPhoneAuth = async () => {
    const server_response = await ajax.countPhoneAuth();
    if (server_response.status == "OK") {
      this.setState({
        totalPhoneAuth: server_response.details.total_p,
      });
    }
  };

  btns = () => {};

  render() {
    return (
      <div className="page">
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Activity"
              previousPageTitle="Dashboard"
              //back="/dashboard"

              // options={{
              //   "customOptions":<this.customHeaderOptions/>
              // }}
              buttons={this.btns}
            />

            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="row">
                  <TitleValueCard
                    //  href="/config/notifications"
                    title="Email Validations"
                    value={this.state.totalEmailAuth}
                    icon="fas fa-envelope"
                    color="icon-service rounded-circle bg-primary-transparent text-primary"
                  />

                  <TitleValueCard
                    // href="/config/notifications"
                    title="Phone Validations"
                    value={this.state.totalPhoneAuth}
                    icon="fas fa-phone"
                    color="icon-service rounded-circle bg-success-transparent text-success"
                  />

                  <TitleValueCard
                    // href="/config/notifications"
                    title="Locked Accounts"
                    value={this.state.totalLockedAccount}
                    icon="fas fa-lock"
                    color="icon-service rounded-circle bg-secondary-transparent text-secondary"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-sm-12">
                <PhoneValidation />
              </div>

              <div className="col-md-4 col-sm-12">
                <EmailValidation />
              </div>
            </div>
          </div>
        </div>
        <RightSideBar />
        <Footer />
      </div>
    );
  }
}

export default Activity;
