import React from "react";
import LeftSideBar from "../Common/LeftSideBar";
import TopBar from "../Common/TopBar";
import ContactSearch from "../Betting/ContactSearch";
import RightSideBar from "../Common/RightSideBar";
import Footer from "../Common/Footer";
import functions from "../../functions";

function AppContainer(props) {
  // console.log(props);
  return (
    <div className="page">
      <LeftSideBar />
      <div
        className="main-content side-content pt-0"
        onClick={functions.closeSystemDrawer}
      >
        <TopBar />
        <div className="container-fluid">{props.children}</div>
      </div>
      <ContactSearch />
      <RightSideBar />
      <Footer />
    </div>
  );
}

export default AppContainer;
