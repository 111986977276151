import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import PageNav from "../Common/PageNav";
import TextInput from "../Common/TextInput";

export default class Statistics extends Component {
  state = {
    statistics: false,
    loading: false,
    message: false,
    date_from: null,
    date_to: null,
  };

  componentDidMount() {
    this.getBettingStatistics();
  }

  getBettingStatistics = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.getBettingStatistics(
      this.state.date_from,
      this.state.date_to
    );
    this.setState({ loading: false });
    // console.log(server_response)

    if (server_response.status == "OK") {
      this.setState({
        statistics: server_response.details,
      });
      return;
    }

    this.setState({
      statistics: "404",
      message: server_response.message,
    });
  };

  onChangeDateFrom = (event) => {
    this.setState({
      date_from: event.target.value,
    });
  };

  onChangeDateTo = (event) => {
    this.setState({
      date_to: event.target.value,
    });
  };

  onSubmitRange = () => {
    const { date_from, date_to } = this.state;

    if (!date_from || !date_to) {
      alert("Select all the dates please!");
      return;
    } else {
      this.setState(
        {
          statistics: false,
        },
        () => this.getBettingStatistics()
      );
    }
  };

  render() {
    const statistics = this.state.statistics;
    return (
      <div className="col-lg-12 col-sm-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-1">
                <div className="row">
                  <div className="col-lg-5">
                    <TextInput
                      label="Date From"
                      type="date"
                      onChange={() => this.onChangeDateFrom}
                      value={this.state.date_from}
                    />
                  </div>
                  <div className="col-lg-5">
                    <TextInput
                      label="Date To"
                      type="date"
                      onChange={() => this.onChangeDateTo}
                      value={this.state.date_to}
                    />
                  </div>
                  <div className="col-lg-2">
                    <button
                      className="btn btn-success"
                      style={{ width: "100%" }}
                      onClick={this.onSubmitRange}
                    >
                      Sort
                    </button>
                  </div>
                </div>
              </h6>
            </div>
            {/* <div className=" border-top"> */}
            <div className="row">
              <div className="col-md-12">
                <div className="card custom-card">
                  {statistics && statistics != "404" && (
                    <div className="row">
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                          <h6 className="mb-0">Total Paid Tips</h6>
                          <h2 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.paid.total_c}
                            </span>
                          </h2>
                          {/* <h6 className="mb-0" style={{ textAlign: 'left' }}>Passed <span className="number-font text-success float-right">{statistics.passed.total_c}</span></h6>
                                                <h6 className="mb-0" style={{ textAlign: 'left' }}>Failed <span className="number-font text-danger float-right">{statistics.failed.total_c}</span></h6> */}
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                          <h6 className="mb-0">Total Unpaid Tips</h6>
                          <h2 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.unpaid.total_c}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                          <h6 className="mb-0">Total Amount Collected (ugx)</h6>
                          <h3 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.total_amount.total_c}
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                          <h6 className="mb-0">New Subscribers</h6>
                          <h2 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.new.total_c}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0 border-right">
                        <div className="card-body text-center">
                          <h6 className="mb-0">Total Passed Tips</h6>
                          <h2 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.passed.total_c}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-4 col-sm-6 pr-0 pl-0">
                        <div className="card-body text-center">
                          <h6 className="mb-0">Total Failed Tips</h6>
                          <h2 className="mb-1 mt-2 number-font">
                            <span className="counter">
                              {statistics.failed.total_c}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  )}
                  {!statistics && <ActivityLoader />}
                  {this.state.message && (
                    <h5 className="text-danger">{this.state.message}</h5>
                  )}
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}
