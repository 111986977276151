import React from "react";
import functions from "../functions";
import ActivityLoader from "../Components/Common/ActivityLoader";
import AlertError from "../Components/Common/AlertError";
import dictionary from "../dictionary";
import ajax from "../ajax";
import AlertSuccess from "../Components/Common/AlertSuccess";

class SetPassword extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    password: "",
    rePassword: "",
    info: "",
  };

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  onChangeRePassword = (event) => {
    this.setState({ rePassword: event.target.value });
  };

  onPressConfirm = async (event) => {
    event.preventDefault();
    const { password, rePassword } = this.state;
    if (password.length > 0 && rePassword.length > 0) {
      if (password === rePassword) {
        const server_reponse = await ajax.securePassword(
          this.state.userId,
          password
        );
        if (server_reponse.status === "OK") {
          this.setState(
            {
              info: <AlertSuccess message={server_reponse.details[0]} />,
            },
            () => {
              //logout user
              window.localStorage.removeItem("quickpost@user");
              window.location.replace("/");
            }
          );
        } else {
          this.setState({
            info: <AlertError message={server_reponse.details[0]} />,
          });
        }
        //redirect
      } else {
        this.setState({
          info: <AlertError message="Passwords do not match" />,
        });
      }
    } else {
      this.setState({
        info: <AlertError message={dictionary._completeFields} />,
      });
    }
  };

  buttons = () => {
    return (
      <div className="btn btn-list">
        {functions.findInObject(this.state.accessInfo, "RSV-WITHDRAW-1") && (
          <a
            className="btn ripple btn-primary"
            href="#"
            onClick={this.onClickWithdrawReserve}
          >
            <i className="fe fe-download"></i> Withdraw Reserve
          </a>
        )}
        {functions.findInObject(this.state.accessInfo, "RSV-TRANSFER-1") && (
          <a
            className="btn ripple btn-secondary"
            href="#"
            onClick={this.onClickTransferFloat}
          >
            <i className="fe fe-external-link"></i> Transfer Float
          </a>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="page main-signin-wrapper">
        <div className="row text-center pl-0 pr-0 ml-0 mr-0">
          <div className="col-lg-3 d-block mx-auto">
            <div className="text-center mb-2">
              {/* <img src={process.env.PUBLIC_URL + "../../images/qp_b.png"} className="header-brand-img" alt="logo"/>
						<img src={process.env.PUBLIC_URL + "../../images/qp_b.png"} className="header-brand-img theme-logos" alt="logo"/> */}
            </div>
            <div className="card login-card">
              <div className="card-body">
                <h4 className="text-center">Password Setup</h4>
                {this.state.info}
                {this.state.loading && <ActivityLoader />}
                <form method="post" onSubmit={this.onPressConfirm}>
                  <div className="form-group text-left">
                    <label>New Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your New Password"
                      type="Password"
                      onChange={this.onChangePassword}
                    />
                  </div>
                  <div className="form-group text-left">
                    <label>Confirm Password</label>
                    <input
                      className="form-control"
                      placeholder="Confirm your password"
                      type="password"
                      onChange={this.onChangeRePassword}
                    />
                  </div>

                  <button className="btn ripple btn-main-primary btn-block">
                    Set Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetPassword;
