import React, { Component } from "react";
import PageNav from "../Common/PageNav";
import ajax from "../../ajax";
import $ from "jquery";
import PopSetResult from "./PopSetResult";
import PopEditMatchName from "../Matches/PopEditMatchName";
import functions from "../../functions";
import ActivityLoader from "../Common/ActivityLoader";

export default class MatchesList extends Component {
  state = {
    matches: false,
    metaData: false,
    message: false,
    loading: false,
    page: 1,
    pagination: false,
    metaData: false,
    setResult: false,
    predictions: false,
    mdlEdit: false,
    selectedTransId: false,
  };

  componentDidMount() {
    this.BettingMatchesList();
    this.BettingPredictionsList();
  }

  BettingMatchesList = async () => {
    const server_response = await ajax.BettingMatchesList(this.state.page);
    // console.log(server_response);
    if (server_response.status === "OK") {
      this.setState(
        {
          matches: server_response.details.list,
          metaData: server_response.details.meta,
        },
        () => this.paginate()
      );
    } else {
      this.setState({
        matches: "404",
      });
    }
  };

  BettingPredictionsList = async () => {
    const server_response = await ajax.BettingPredictionsList();
    if (server_response.status === "OK") {
      this.setState({
        predictions: server_response.details,
      });
    } else {
      this.setState({
        predictions: "404",
      });
    }
  };

  /////////////////////////////pagination//////////////////////////

  paginate = () => {
    if (this.state.metaData) {
      this.setState(
        {
          pagination: false,
        },
        () => {
          this.setState({
            pagination: (
              <PageNav
                totalPages={this.state.metaData.total_pages}
                pageList={this.state.metaData.list_of_pages}
                currentPage={this.state.page}
                onClickNext={this.onClickNext}
                onClickPage={this.onClickPage}
                onClickPrevious={this.onClickPrevious}
              />
            ),
          });
        }
      );
    }
  };
  onClickPage = (page) => {
    this.setState(
      {
        page: page,
        matches: false,
      },
      () => this.BettingMatchesList()
    );
  };

  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.totalcpages * 1) {
      this.setState(
        {
          page: this.state.page + 1,
          matches: false,
        },
        () => {
          this.BettingMatchesList();
        }
      );
    }
  };

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          matches: false,
        },
        () => {
          this.BettingMatchesList();
        }
      );
    }
  };

  ///////////////////////eend//////////////////////////

  onClickSetResult = (id) => {
    this.setState(
      {
        setResult: (
          <PopSetResult
            matches={this.state.matches}
            id={id}
            predictions={this.state.predictions}
            onchange={this.onChangeResult}
            onrenamematch={this.onrenamematch}
          />
        ),
      },
      () => {
        $("#modal_set_result").modal("show");
      }
    );
  };

  onChangeResult = (data) => {
    if ((data = "OK")) {
      this.setState(
        {
          matches: false,
        },
        () => {
          this.BettingMatchesList();
        }
      );
    }
  };

  onrenamematch = (id) => {
    this.setState(
      { matches: false },
      () => {
        this.BettingMatchesList();
      },
      () => {
        this.onClickSetResult(id);
      }
    );
  };

  onClickMatchName = (record) => {
    this.setState(
      {
        mdlEdit: false,
      },
      () => {
        this.setState(
          {
            mdlEdit: <PopEditMatchName matchInfo={record} />,
          },
          () => {
            functions.openPopup("modal_edit_match_name");
          }
        );
      }
    );
  };

  onClickRow = (selectedTransId) => {
    this.setState({
      selectedTransId,
    });
  };

  render() {
    const { matches, metaData } = this.state;

    return (
      <>
        {this.state.setResult}
        {this.state.mdlEdit}
        <div className="col-lg-12 col-sm-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">
                  Matches
                  {/* <span className="float-right m-10">
                          <Link
                            class="btn ripple btn-success btn-sm"
                            to="/all-paysoon"
                          >
                            See All
                          </Link>
                        </span> */}
                </h6>
                <p className="text-muted card-sub-title">
                  Matches that will soon be played
                </p>
              </div>
              {!matches && <ActivityLoader />}
              <div className="user-manager scroll-widget border-top overflow-auto">
                <div className="table-responsive">
                  <table className="table table-bordered  text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>#No</th>
                        <th>Match Name</th>
                        {functions.levelGuard() == 1 && <th>Added By</th>}
                        <th>Match Time</th>
                        <th>Prediction</th>
                        <th>Price</th>
                        <th>Odd</th>
                        <th>Bet. Deadline</th>
                        <th>Result</th>
                        <th>Match ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matches &&
                        Array.isArray(matches) &&
                        matches.map((match, key) => (
                          <tr
                            key={key}
                            onClick={() => this.onClickRow(match.match_id)}
                            className={`${
                              this.state.selectedTransId == match.match_id
                                ? "flag-row"
                                : ""
                            } hot-tr`}
                          >
                            <td>{metaData.offset_count + key + 1}</td>
                            <td>{match.match_name}</td>
                            {functions.levelGuard() == 1 && (
                              <td>
                                {match.tipstar &&
                                  match.tipstar.first_name +
                                    " " +
                                    match.tipstar.last_name}
                              </td>
                            )}
                            <td>{match.match_time.when}</td>
                            <td>{match.prediction.prediction}</td>
                            <td>{match.price.price_name}</td>
                            <td>{match.match_odd}</td>
                            <td>{match.betting_deadline.when}</td>
                            {!match.match_results && (
                              <td>
                                <span
                                  onClick={() =>
                                    this.onClickSetResult(match.match_id)
                                  }
                                  className="btn btn-primary btn-sm"
                                >
                                  Set Result
                                </span>
                              </td>
                            )}

                            {match.match_results &&
                              match.match_results.prediction ==
                                match.prediction.prediction && (
                                <td
                                  style={{
                                    backgroundColor: "rgba(0,255,0,0.3)",
                                  }}
                                >
                                  {match.match_results.prediction}
                                </td>
                              )}
                            {match.match_results &&
                              match.match_results.prediction !=
                                match.prediction.prediction && (
                                <td
                                  style={{
                                    backgroundColor: "rgba(255,0,0,0.3)",
                                  }}
                                >
                                  {match.match_results.prediction}
                                </td>
                              )}
                            <td>{match.match_id}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {this.state.pagination}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
