const apiHost = "https://sureapi.vendorpayug.com/";
// const apiHost = "http://localhost/sure_odds/";

const ACCESS_TOKEN = localStorage.getItem("quickpost@user");

export default {
  async loginUser(username, password) {
    let data = {
      username: username,
      password: password,
    };
    try {
      let response = await fetch(apiHost + "admin/login", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addBettingBatch(batch_name, price_id) {
    ///henry
    let data = {
      batch_name: batch_name,
      price_id: price_id,
    };
    try {
      let response = await fetch(apiHost + "add/betting/batch", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addBettingMatch(match_name, datetime, batch_id, prediction_id) {
    ///henry
    let data = {
      match_name: match_name,
      datetime: datetime,
      batch_id: batch_id,
      prediction_id: prediction_id,
    };
    try {
      let response = await fetch(apiHost + "add/betting/match", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addBettingPrediction(prediction) {
    ///henry
    let data = {
      prediction: prediction,
    };
    try {
      let response = await fetch(apiHost + "add/betting/prediction", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addBettingPrice(price, amount) {
    ///henry
    let data = {
      price: price,
      amount: amount,
    };
    try {
      let response = await fetch(apiHost + "betting/add/price", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async withdrawRequest(phone, amount) {
    ///henry
    let data = {
      phone: phone,
      amount: amount,
    };
    try {
      let response = await fetch(apiHost + "betting/withdraw/request", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async addBettingTipstar(first_name, last_name, username) {
    ///henry
    let data = {
      first_name: first_name,
      last_name: last_name,
      username: username,
    };
    try {
      let response = await fetch(apiHost + "user/admin/register", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async listTipstars() {
    try {
      let response = await fetch(apiHost + "admin/users", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async setBettingMatchResult(result) {
    ///henry
    let data = {
      result: result,
    };
    try {
      let response = await fetch(apiHost + "set/betting/matches/result", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingMatchesPerBatch(batch_id) {
    ///henry
    let data = {
      batch_id: batch_id,
    };
    try {
      let response = await fetch(apiHost + "get/betting/matches/per/batch", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getExpiredBettingMatchesToday() {
    ///henry
    try {
      let response = await fetch(
        apiHost + "get/expired/betting/matches/today",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + ACCESS_TOKEN,
            Accept: "application/json",
            "Content-Type": "application/json",
            Source: "Admin",
          },
        }
      );

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingUnsuccessfulMatches() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/unsuccessful/matches", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingSuccessfulMatches() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/successful/matches", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingPrices() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/prices", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingPredictions() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/predictions", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingMatches() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/matches", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingBatches() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/batches", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingMatchesToday() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/matches/today", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingMatchesExpired() {
    ///henry
    try {
      let response = await fetch(apiHost + "get/betting/matches/expired", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingMatchesInBatch(batch_id) {
    ///henry
    let data = {
      batch_id: batch_id,
    };
    try {
      let response = await fetch(apiHost + "get/betting/matches/in/batch", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async newBettingMatch(
    match_name,
    price_id,
    prediction_id,
    match_time,
    match_odd,
    betting_deadline
  ) {
    ///henry
    let data = {
      match_name: match_name,
      price_id: price_id,
      prediction_id: prediction_id,
      match_time: match_time,
      match_odd: match_odd,
      betting_deadline: betting_deadline,
    };
    try {
      let response = await fetch(apiHost + "betting/matches/new", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async BettingPredictionsList() {
    ///henry

    try {
      let response = await fetch(apiHost + "betting/predictions/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async BettingPricesList() {
    ///henry

    try {
      let response = await fetch(apiHost + "betting/prices/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async BettingRequestsList() {
    ///henry

    try {
      let response = await fetch(apiHost + "betting/requests/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async BettingMatchesList(page) {
    let data = {
      page: page,
    };
    try {
      let response = await fetch(apiHost + "betting/matches/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async UpdateBettingMatchResult(match_id, prediction_id) {
    ///henry
    let data = {
      match_id: match_id,
      prediction_id: prediction_id,
    };
    try {
      let response = await fetch(apiHost + "betting/matches/results/update", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async BettingPassedTips(match_day) {
    ///henry
    let data = {
      match_day: match_day,
    };
    try {
      let response = await fetch(apiHost + "betting/tips/passed/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async countMatches() {
    try {
      let response = await fetch(apiHost + "betting/matches/count", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },
  async countMatchWins() {
    try {
      let response = await fetch(apiHost + "betting/matches/wins/count", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async countPaidTips() {
    try {
      let response = await fetch(apiHost + "betting/tips/paid/count", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async countUnPaidTips() {
    try {
      let response = await fetch(apiHost + "betting/tips/unpaid/count", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async bettingTips(page, date, payment) {
    let data = {
      page: page,
      date: date,
      payment: payment,
    };

    try {
      let response = await fetch(apiHost + "betting/tips", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async getBettingStatistics(date_from, date_to) {
    let data = {
      date_from: date_from,
      date_to: date_to,
    };

    console.log(data);

    try {
      let response = await fetch(apiHost + "get/betting/statistics", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async updateMatchName(match_id, match_name) {
    let data = {
      match_id: match_id,
      match_name: match_name,
    };

    try {
      let response = await fetch(apiHost + "update/match/name", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },

  async bettingContactSearchMessages(contact) {
    ///henry
    let data = {
      contact: contact,
    };
    try {
      let response = await fetch(apiHost + "betting/contacts/search/messages", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },
  async securePassword(user_id, password) {
    let data = {
      admin_id: user_id,
      password: password,
    };
    try {
      let response = await fetch(apiHost + "user/admin/set_password", {
        method: "POST",
        headers: {
          //Authorization: "Bearer " + ACCESS_TOKEN,
          Accept: "application/json",
          "Content-Type": "application/json",
          Source: "Admin",
        },
        body: JSON.stringify(data),
      });

      let result = await response.json();

      return result;
    } catch (error) {
      let connError = { status: "conn", details: ["Contacting server...."] };
      return connError;
    }
  },
};
