import React, { useState, useEffect } from "react";
import $ from "jquery";
import AppContainer from "../../Components/Structure/AppContainer";
import PageHeader from "../../Components/Common/PageHeader";
import PopAddTipstar from "../../Components/Betting/PopAddTipstar";
import ajax from "../../ajax";
import ActivityLoader from "../../Components/Common/ActivityLoader";

function TipStars() {
  const [addTipstar, setAddTipstar] = useState(false);
  const [tipstars, setTipstars] = useState(false);
  const [page, setPage] = useState({ page: 1 });

  const Buttons = () => {
    return (
      <div className="btn btn-list">
        <a
          className="btn ripple btn-success"
          href="#"
          onClick={onClickAddTipstar}
        >
          <i className="fe fe-plus-square"></i> Add Tip Star
        </a>
      </div>
    );
  };

  const onClickAddTipstar = () => {
    setAddTipstar(<PopAddTipstar getList={BettingTipstarsList} />);
  };

  useEffect(() => {
    if (addTipstar) {
      $("#modal_add_tipstar").modal("show");
    }
  }, [addTipstar]);

  useEffect(() => {
    BettingTipstarsList();
  }, [page]);

  const BettingTipstarsList = async () => {
    const server_response = await ajax.listTipstars();
    if (server_response.status === "OK") {
      setTipstars(server_response.details);
    } else {
      setTipstars("404");
    }
  };

  return (
    <AppContainer>
      {addTipstar}
      <PageHeader
        title="Tip Stars"
        previousPageTitle="Dashboard"
        buttons={<Buttons />}
      />
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-1">Tip Stars</h6>
                <p className="text-muted mb-1 card-sub-title">
                  Tip stars registered with sure odds
                </p>
              </div>
              <div className="user-manager scroll-widget border-top overflow-auto">
                {/* <ActivityLoader /> */}
                {!tipstars && <ActivityLoader />}
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th style={{ height: "40px" }}>#No</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Otp</th>
                        <th>Date Added</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tipstars &&
                        Array.isArray(tipstars) &&
                        tipstars.map((tipstar, key) => (
                          <tr className="border-bottom">
                            <td className="bd-t-0">
                              <div className="main-img-user">{key + 1}</div>
                            </td>
                            <td className="bd-t-0">
                              {tipstar.first_name} {tipstar.last_name}
                            </td>
                            <td className="bd-t-0">{tipstar.username}</td>
                            <td className="bd-t-0">{tipstar.otp}</td>
                            <td className="bd-t-0">
                              {tipstar.created_at.long_date}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default TipStars;
