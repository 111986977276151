import React, { Component } from "react";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import PageNav from "../Common/PageNav";
import TextInput from "../Common/TextInput";
import functions from "../../functions";

export default class Tips extends Component {
  state = {
    tips: false,
    loading: false,
    message: false,
    page: 1,
    pagination: false,
    metaData: false,
    date: null,
    payment: null,
  };

  componentDidMount() {
    this.listBettingTips();
  }

  listBettingTips = async () => {
    this.setState({ loading: true });
    const server_response = await ajax.bettingTips(
      this.state.page,
      this.state.date,
      this.state.payment
    );
    this.setState({ loading: false });
    // console.log(server_response)

    if (server_response.status == "OK") {
      this.setState(
        {
          tips: server_response.details.list,
          metaData: server_response.details.meta,
        },
        () => this.paginate()
      );
      return;
    }

    this.setState({
      tips: "404",
      message: server_response.message,
    });
  };

  onChangeDate = (event) => {
    this.setState(
      {
        date: event.target.value,
        tips: false,
      },
      () => this.listBettingTips()
    );
  };

  onChangePayment = (event) => {
    this.setState(
      {
        payment: event.target.value != "2" ? event.target.value : null,
        tips: false,
      },
      () => this.listBettingTips()
    );
  };

  paginate = () => {
    if (this.state.metaData) {
      this.setState(
        {
          pagination: false,
        },
        () => {
          this.setState({
            pagination: (
              <PageNav
                totalPages={this.state.metaData.total_pages}
                pageList={this.state.metaData.list_of_pages}
                currentPage={this.state.page}
                onClickNext={this.onClickNext}
                onClickPage={this.onClickPage}
                onClickPrevious={this.onClickPrevious}
              />
            ),
          });
        }
      );
    }
  };
  onClickPage = (page) => {
    this.setState(
      {
        page: page,
        tips: false,
      },
      () => this.listBettingTips()
    );
  };

  onClickNext = () => {
    //increment page numbers
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 + 1 <= metaData.totalcpages * 1) {
      this.setState(
        {
          page: this.state.page + 1,
          tips: false,
        },
        () => {
          this.listBettingTips();
        }
      );
    }
  };

  onClickPrevious = () => {
    const metaData = this.state.metaData;
    if (metaData.current_page * 1 > 1) {
      this.setState(
        {
          page: this.state.page - 1,
          tips: false,
        },
        () => {
          this.listBettingTips();
        }
      );
    }
  };

  render() {
    const tips = this.state.tips;
    return (
      <div className="col-lg-12 col-sm-12">
        <div className="card custom-card">
          <div className="card-body">
            <div>
              <h6 className="card-title mb-1">
                Betting Tips
                <span className="float-right">
                  <TextInput
                    label="Date"
                    type="date"
                    onChange={() => this.onChangeDate}
                    value={this.state.date}
                  />
                </span>
                <span className="float-right d-flex align-items-center mr-3">
                  <label class="mg-b-0 mr-3">Payment</label>
                  <select
                    className="form-control"
                    onChange={(e) => this.onChangePayment(e)}
                  >
                    <option value="2">All</option>
                    <option value="1">Paid</option>
                    <option value="0">Unpaid</option>
                  </select>
                </span>
              </h6>
              <p className="text-muted card-sub-title">Betting tips today</p>
            </div>
            {!tips && <ActivityLoader />}
            <div className="user-manager scroll-widget border-top overflow-auto">
              <div className="table-responsive">
                <table className="table table-bordered table-striped text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>#No</th>
                      {functions.levelGuard() == 1 && <th>TipStar</th>}
                      <th>Phone Number</th>
                      <th>Price</th>
                      <th>Message</th>
                      <th>Paid</th>
                      <th>Passed</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tips &&
                      Array.isArray(tips) &&
                      tips.map((tip, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>

                          {functions.levelGuard() == 1 && (
                            <td>
                              {tip.tipstar &&
                                tip.tipstar.first_name +
                                  " " +
                                  tip.tipstar.last_name}
                            </td>
                          )}
                          <td>{tip.phone}</td>
                          <td>
                            {tip.price_name}
                            <br />

                            {tip.price}
                          </td>
                          <td
                            style={{
                              whiteSpace: "pre-wrap",
                              maxWidth: "600px",
                            }}
                          >
                            {tip.message}
                          </td>
                          <td>{tip.paid == "1" ? "true" : "false"}</td>
                          <td>{tip.passed == "1" ? "true" : "false"}</td>
                          <td>{tip.date.long_date + " " + tip.date.time}</td>
                        </tr>
                      ))}
                  </tbody>
                  {this.state.message && (
                    <h5 className="text-danger">{this.state.message}</h5>
                  )}
                </table>
                {this.state.pagination}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
