import React from "react";
import PageHeader from "../../Components/Common/PageHeader";
import functions from "../../functions";
import PassedTips from "../../Components/Betting/PassedTips";
import Tips from "../../Components/Betting/Tips";
import AppContainer from "../../Components/Structure/AppContainer";

class BettingDashboard extends React.Component {
  state = {
    userId: functions.sessionGuard(),
  };

  buttons = () => {};

  render() {
    const { prices } = this.state;
    return (
      <AppContainer>
        <PageHeader
          title="Betting Dashboard"
          previousPageTitle="Dashboard"
          buttons={this.buttons}
        />
        {this.state.setResult}

        <div className="row">
          {/* <Matches /> */}

          <PassedTips />

          <Tips />
        </div>
      </AppContainer>
    );
  }
}

export default BettingDashboard;
